<template>
  <LiefengContent>
    <template v-slot:title>服务工单</template>
    <template v-slot:toolsbarRight>
      <Form :model="searchData" :inline="true" class="search">
        <FormItem style="margin-bottom: 0">
          <Input
            v-model.trim="searchData.keyword"
            placeholder="通过工单名称、服务内容或服务记录查询"
            search
            enter-button="查询"
            style="width: 330px"
            @on-search="searchFn"
          ></Input>
        </FormItem>
        <Button type="primary" style="margin-right: 10px" @click="openCreateStatusFn(true, 'open')"
          >创建工单</Button
        >
        <Button type="primary" style="margin-right: 10px" @click="navigateToServiceRequest"
          >服务请求</Button
        >
        <Button type="primary" @click="navigateToServiceList"
          >服务清单</Button
        >
      </Form>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :curPage="page"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100, 200]"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>

      <!-- 查看 -->
      <LiefengModal
        :title="openSeeTitle"
        :value="openSeeStatus"
        @input="openSeeStatusFn"
        width="80%"
        height="calc(100vh - 100px)"
      >
        <template v-slot:contentarea>
          <div class="see-modal">
            <div class="steps">
              <Steps :current="currentStep" size="small">
                <Step
                  :title="item.title"
                  :content="item.content"
                  v-for="(item, index) in seeStepsList"
                  :status="item.status"
                  :key="index"
                ></Step>
              </Steps>
            </div>
            <div class="content">
              <Card class="card">
                <p slot="title">工单信息</p>
                <div>
                  <span class="tf-title">创建时间：</span>
                  {{ seeRowData.gmtCreate | filterTime(that) }}
                </div>
                <div>
                  <span class="tf-title">工单名称：</span> {{ seeRowData.name }}
                </div>
                <!-- <div>类型：{{seeRowData.serviceType}}</div> -->
                <div>
                  <span class="tf-title">工单类别：</span>
                  {{ filterType(seeRowData.orderType) }}
                </div>
                <div>
                  <span class="tf-title">服务内容：</span
                  >{{ seeRowData.content }}
                </div>
                <div>
                  <span class="tf-title">支付状态：</span
                  >{{ seeRowData.payStatus == "1" ? `待支付` : `已支付` }}
                  <span style="color: #2d8cf0"
                    >{{ seeRowData.payMoney || 0 }}元</span
                  >
                </div>
                <div>
                  <span class="tf-title" v-if="seeRowData.serviceStatus">服务状态：</span
                  >{{ seeRowData.serviceStatus | filtersServiceStatus(that) }}
                </div>
                <div>
                  <span class="tf-title" v-if="seeRowData.serviceResult">服务记录：</span
                  >{{ seeRowData.serviceResult }}
                </div>
                <div>
                  <span class="tf-title" v-if="seeRowData.evaluate">评价等级：</span
                  >{{ seeRowData.evaluate }}
                </div>
                <div>
                  <span class="tf-title" v-if="seeRowData.evaluateContent">评价内容：</span
                  >{{ seeRowData.evaluateContent }}
                </div>
                <div>
                  <span class="tf-title" v-if="seeRowData.staffName">业务员：</span
                  >{{ seeRowData.staffName }}
                </div>
                <div class="list">
                  <span class="tf-title">服务清单列表：</span>
                  <LiefengTable
                    :talbeColumns="seeTalbeColumns"
                    :tableData="filterService(seeRowData.serviceApplyItemList)"
                    :hidePage="true"
                    class="seeTable"
                  ></LiefengTable>
                </div>
              </Card>
            </div>
          </div>
        </template>
        <template v-slot:toolsbar>
          <Button type="primary" @click="openSeeStatusFn(false)">关闭</Button>
        </template>
      </LiefengModal>

      <!-- 派单 -->
      <LiefengModal
        :title="dispatchTitle"
        :value="dispatchStatus"
        @input="dispatchFn"
        :fullscreen="true"
        class="dispatch"
      >
        <template v-slot:contentarea>
          <div class="dispatch-modal">
            <div class="content">
              <Card class="card">
                <p slot="title">工单信息</p>
                <div>
                  <span class="tf-title">创建时间：</span>
                  {{ dispatchForm.gmtCreate | filterTime(that) }}
                </div>
                <div>
                  <span class="tf-title">工单名称：</span>
                  {{ dispatchForm.name }}
                </div>
                <div>
                  <span class="tf-title">工单类别：</span>
                  {{ filterType(dispatchForm.orderType) }}
                </div>
                <div class="list">
                  <span class="tf-title">服务清单列表：</span>
                  <LiefengTable
                    :talbeColumns="seeTalbeColumns"
                    :tableData="filterService(dispatchForm.serviceApplyItemList)"
                    :hidePage="true"
                    class="seeTable"
                  ></LiefengTable>
                </div>
              </Card>
              <Card class="card" style="margin-top: 14px">
                <p slot="title">{{currentDisaptchOrCancel ? '选择业务员' : '服务记录'}}</p>
                <Form
                  :model="dispatchFormData"
                  :rules="dispatchValidateObj"
                  :label-width="90"
                  ref="dispatchForm"
                  :label-colon="true"
                  v-if="dispatchStatus"
                >
                  <FormItem label="业务员" prop="staffId" v-if="currentDisaptchOrCancel">
                    <Select v-model="dispatchFormData.staffId" filterable>
                      <Option
                        v-for="item in staffList"
                        placeholder="请选择业务员"
                        :value="dispatchFormData.custGlobalId"
                        :key="item.custGlobalId"
                        >{{ item.name }}</Option
                      >
                    </Select>
                  </FormItem>
                  <FormItem :label-width='0' v-else>
                    <Input
                      v-model="dispatchFormData.serviceResult"
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 10 }"
                      placeholder="请填写服务记录"
                    ></Input>
                  </FormItem>
                </Form>
              </Card>
            </div>
          </div>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="dispatchFn(false)">取消</Button>
          <Button
            type="primary"
            @click="saveDispatchFn"
            style="margin-left: 10px"
            >{{currentDispatchStatus ? '提交中...' : '提交'}}</Button
          >
        </template>
      </LiefengModal>
      <!-- 选择服务对象 -->
      <LiefengModal
        :title="selectObjTitle"
        :value="serviceApplyStatus"
        @input="serviceApplyStatusFn"
        width="60%"
        height="calc(100vh - 200px)"
        class="select_obj"
      >
      <template v-slot:contentarea>
        <LiefengTable
          :talbeColumns="serviceApplyColumns"
        :tableData="serviceApplyList"
        :loading="serviceApplyLoading"
        :highlightRow="true"
        @currentChange="currentChange"
        :curPage="serviceApplyPage"
        :total="serviceApplyTotal"
        :pagesizeOpts="[20, 30, 50, 100, 200]"
        :page-size="serviceApplyPageSize"
        @hadlePageSize="serviceApplyPageSizeFn"
        :height="serviceApplyHeight"
        ></LiefengTable>
      </template>
      <template v-slot:toolsbar>
          <Button type="info" @click="serviceApplyStatusFn(false)">取消</Button>
          <Button
            type="primary"
            style="margin-left: 10px"
            @click="saveServiceApply"
            >确定</Button
          >
        </template>
      </LiefengModal>
      <!-- 创建工单 -->
      <LiefengModal
        title="创建工单"
        :value="openCreateStatus"
        @input="openCreateStatusFn"
        width="80%"
        height="calc(100vh - 100px)"
      >
        <template v-slot:contentarea>
          <Form
            :model="createFormData"
            :rules="createValidateObj"
            :label-width="90"
            ref="createForm"
            :label-colon="true"
            v-if="openCreateStatus"
          >
            <FormItem label="工单名称" prop="name">
              <Input
                v-model="createFormData.name"
                placeholder="请输入工单名称"
              ></Input>
            </FormItem>
            <FormItem label="工单类别" prop="orderType">
              <Select
                v-model="createFormData.orderType"
                placeholder="请选择工单类别"
                @on-change="changeOrderType"
              >
                <Option value="15">常规服务</Option>
                <Option value="14">平台施工</Option>
                <Option value="407">服务请求</Option>
                <Option value="408">设备预警</Option>
              </Select>
              <Button type="success" v-if="createFormData.orderType == '408'" style="margin-top:20px" @click="serviceApplyStatusFn(true)">选择预警设备</Button>
              <Button type="success" v-if="createFormData.orderType == '407'" style="margin-top:20px" @click="serviceApplyStatusFn(true)">选择服务请求</Button>
            </FormItem>
            <FormItem
              label="服务对象"
              prop="userId"
              v-if="createFormData.userId || createFormData.orderType == '14' || createFormData.orderType == '15'"
            >
              <Button type="success" @click="serviceApplyStatusFn(true)" v-if="createFormData.orderType == '14' || createFormData.orderType == '15'">选择服务对象</Button>
              <span v-if="createFormData.userId" :style="{marginLeft: (createFormData.orderType == '14' || createFormData.orderType=='15') ? '20px' : '0'}">当前服务对象：<span style="color: red">{{serviceApplyUserObj.userName}}</span></span>
            </FormItem>

             <FormItem label="服务描述" prop="content">
              <Input
                v-model="createFormData.content"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 6 }"
                placeholder="请填写服务要求，限400字"
                show-word-limit
                :maxlength="400"
              ></Input>
            </FormItem>
            <FormItem class="services" :error="errorServices">
              <span class="validate" slot="label">服务清单：</span>
              <LiefengTable
                :talbeColumns="createTalbeColumns"
                :tableData="serviceList"
                :hidePage="true"
                @tableSelect="createTableSelect"
              ></LiefengTable>
            </FormItem>
            <FormItem label="是否派单" prop="serviceOrder">
              <RadioGroup v-model="createFormData.serviceOrder">
                <Radio label="1">是</Radio>
                <Radio label="2">否</Radio>
              </RadioGroup>
            </FormItem>
             <FormItem label="服务记录" prop="serviceResult" v-if="createFormData.serviceOrder == '2'">
               <Input
                      v-model="createFormData.serviceResult"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 6 }"
                      placeholder="请填写服务记录"
                    ></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="openCreateStatusFn(false)">取消</Button>
          <Button
            type="primary"
            style="margin-left: 10px"
            @click="saveCreateFn"
            >{{ currentCreateStatus ? "提交中..." : "提交" }}</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/serviceorderorgplatform')
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      that: this,
      seeStepsList: [
        { title: "创建工单", content: "", status: "finish" },
        { title: "派单", content: "", status: "process" },
        { title: "接单", content: "", status: "wait" },
        { title: "已完成", content: "", status: "wait" },
      ],
      currentStep: 1,
      searchData: {
        keyword: "",
      },
      talbeColumns: [
        {
          title: "序号",
          width: 80,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              this.pageSize * (this.page - 1) + params.index + 1
            );
          },
        },
        {
          title: "工单名称",
          align: "center",
          key: "name",
          width: 200,
        },
        {
          title: "工单类别",
          align: "center",
          key: "name",
          width: 120,
          render: (h, params) => {
            return h("div", this.filterType(params.row.orderType));
          },
        },
        {
          title: "服务描述",
          align: "center",
          key: "content",
          width: 200,
        },
        {
          title: "服务清单列表",
          align: "center",
          width: 200,
          className: 'table-list',
          render: (h, params) => {
            return h(
              "div",
              (() => {
                let result = [];
                if (params.row.serviceIds && params.row.serviceIds.length > 0) {
                  this.serviceList.map((item) => {
                    params.row.serviceIds.map((subItem, subIndex) => {
                      if (item.itemId == subItem) {
                        result.push(
                          h("div",{
                           style: {
                               lineHeight: '48px',
                               borderBottom: subIndex == params.row.serviceIds.length -1 ? '' :'1px solid #e8eaec'
                           }
                       }, item.itemName)
                        );
                      }
                    });
                  });
                  return result;
                } else {
                  return "";
                }
              })()
            );
          },
        },
        {
          title: "服务状态",
          align: "center",
          key: "serviceStatus",
          width: 120,
          render: (h, params) => {
            let result = '';
                this.serviceStatusList.map(item => {
                  if(item.dictKey == params.row.serviceStatus) {
                    result = item.dictValue;
                  }
                })
                return h('div',result)
          },
        },
        {
          title: "服务记录",
          align: "center",
          key: "serviceResult",
          width: 200,
        },

        {
          title: "评价等级",
          align: "center",
          key: "evaluate",
          width: 100,
        },
        {
          title: "评价内容",
          align: "center",
          key: "evaluateContent",
          width: 200,
        },
        {
          title: "业务员",
          align: "center",
          key: "staffName",
          width: 120,
        },
        {
          title: "待支付",
          align: "center",
          key: "payStatus",
          width: 80,
          render: (h, params) => {
            return h(
              "Tooltip",
              {
                props: {
                  placement: "top",
                  content:
                    params.row.payStatus == "1"
                      ? `待支付：${params.row.payMoney} 元`
                      : `已支付：${params.row.payMoney} 元`,
                },
              },
              [
                h(
                  "span",
                  {
                    style: {
                      color:
                        params.row.payStatus == "1" ? "#2b85e4" : "#19be6b",
                      borderBottom:
                        params.row.payStatus == "1"
                          ? "1px solid #2b85e4"
                          : "1px solid #19be6b",
                    },
                  },
                  params.row.payStatus == "1" ? "是" : "否"
                ),
              ]
            );
          },
        },

        {
          title: "创建日期",
          align: "center",
          key: "gmtCreate",
          width: 200,
        },
        {
          title: "操作",
          fixed: "right",
          width: 200,
          align: "center",
          render: (h, params) => {
            return h("div",{
              style: {
                display: 'flex',
                justifyContent: 'space-between'
              }
            }, [
              h(
                "Button",
                {
                  props: {
                    type: "success",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.openSeeTitle = params.row.name;
                      this.seeToRow({
                        orgCode: params.row.orgCode,
                        orderId: params.row.orderId,
                      });
                    },
                  },
                },
                "查看"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                    display: params.row.isDistributeOrder ? 'none' : 'display'
                  },
                  on: {
                    click: () => {
                      this.dispatchTitle = params.row.name;
                      this.currentDisaptchOrCancel = true;
                      this.openToDispatch({
                        orgCode: params.row.orgCode,
                        orderId: params.row.orderId,
                      });
                    },
                  },
                },
                "派单"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  style: {
                    display: params.row.serviceStatus == '5' ? 'none' : 'display'
                  },
                  on: {
                    click: () => {
                      this.dispatchTitle = params.row.name;
                      this.currentDisaptchOrCancel = false;
                      this.openToDispatch({
                        orgCode: params.row.orgCode,
                        orderId: params.row.orderId,
                      });
                    },
                  },
                },
                "取消"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      loading: true,
      page: 1,
      pageSize: 20,
      total: 0,

      // 服务内容列表
      serviceList: [],
      // 服务对象列表
      serviceObjList: [],
      // 服务分类列表
      serviceTypeList: [],
      // 查看
      openSeeStatus: false,
      openSeeTitle: "",
      seeRowData: {},
      seeTalbeColumns: [
        {
          type: "index",
          width: 60,
        },
        {
          title: "名称",
          minWidth: 200,
          key: "itemName",
        },
        {
          title: "简介",
          minWidth: 200,
          key: "introduction",
        },
        {
          title: "单价",
          width: 140,
          key: "price",
          render: (h,params) => {
            return h('div',params.row.price + '元')
          }
        },
        {
          title: "单位",
          width: 140,
          key: "priceUnit",
        },
      ],

      // 派单
      dispatchTitle: "",
      dispatchStatus: false,
      dispatchFormData: {}, //用于派单提交
      dispatchForm: {}, //用于查看回显
      staffList: [], //业务员列表
      currentDispatchStatus: false,
      currentDisaptchOrCancel: true,  //派单/取消标识，true为派单
      dispatchValidateObj: {
        staffId: [{
            required: true,
            min: 1,
            message: "请选择业务员",
            trigger: "change",
          }],
      },
      // 创建工单
      openCreateStatus: false,
      createFormData: { serviceOrder: "1",orderType: '15',userId: '' },
      createValidateObj: {
        name: [{ required: true, message: "请输入工单名称", trigger: "blur" }],
        content: [
          { required: true, message: "请填写服务要求", trigger: "blur" },
          {
            type: "string",
            max: 400,
            message: "请控制在400字以内",
            trigger: "blur",
          },
        ],
        serviceResult: [
          { required: true, message: "请填写服务记录", trigger: "blur" },
        ],
        orderType: [
          { required: true, message: "请选择工单类别", trigger: "change" },
        ],
        serviceOrder: [
          { required: true, message: "请选择是否派单", trigger: "change" },
        ],
        services: [
          {
            required: true,
            min: 1,
            type: "array",
            message: "请选择服务内容",
            trigger: "change",
          },
        ],
        userId: [
          {
            required: true,
            min: 1,
            message: "请选择服务对象",
            trigger: "change",
          },
        ],
      },
      selectServiceList: [],
      currentCreateStatus: false,
      errorServices: "",
      selectServiceOption: [],
      createTalbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "名称",
          minWidth: 200,
          key: "itemName",
        },
        {
          title: "简介",
          minWidth: 200,
          key: "introduction",
        },
        {
          title: "单价",
          width: 120,
          key: "price",
          render: (h,params) => {
            return h('div',params.row.price + '元')
          }
        },
        {
          title: "单位",
          width: 120,
          key: "priceUnit",
        },
        {
          title: "数量",
          width: 190,
          render: (h, params) => {
            return h("InputNumber", {
              props: {
                controlsOutside: true,
                min: 0,
                value: this.selectServiceList[params.index].num,
              },
              on: {
                "on-change": (val) => {
                  this.selectServiceList[params.index].num = val;
                },
              },
            });
          },
        },
        {
          title: "价格",
          width: 120,
          render: (h, params) => {
            return h(
              "div",
              this.selectServiceList[params.index].num * params.row.price + '元'
            );
          },
        },
      ],
       // 创建工单 -- 选择服务对象
       selectObjTitle: '选择服务对象(点击列表项选中服务对象)',
       serviceApplyTypeStatus: '3',  //辨别当前服务对象是1.设备预警/2.服务请求/3.服务对象
      serviceApplyList: [],
      serviceApplyColumns: [],
      serviceApplyLoading: false,
      serviceApplyHeight: '',
      serviceApplyPage: 1,
      serviceApplyPageSize: 20,
      serviceApplyTotal: 0,
      serviceApplyStatus: false,
      serviceApplyUserObj: {},

      serviceStatusList: [],
      orderStatusList: [],
    };
  },
  filters: {
    filtersServiceStatus(val,that) {
      if (val) {
        for(let k in that.serviceStatusList) {
          if(that.serviceStatusList[k].dictKey == val) {
            return that.serviceStatusList[k].dictValue
          }
        }
      }else {
        return ''
      }
    },
    filterTime(val, that) {
      if (!val) return "";
      return that.$core.formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss");
    },
  },
  methods: {
     // 获取状态字典
   async getServiceStatus(type,list) {
     let res = await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList",{
        dictType: type,
        appCode: 'HOMECARE',
      })
    if(res.code == 200 && res.dataList) {
      this[list] = res.dataList;
    }
    },
    // 跳转去服务请求
    navigateToServiceRequest() {
      this.$router.push({
        path: '/serviceorderorgquest'
      })
    },
    navigateToServiceList() {
      this.$router.push({
        path: '/serviceorderorglist'
      })
    },
    filterType(val) {
      switch (val) {
        case "14":
          return "平台施工";
        case "15":
          return "常规服务";
        case "407":
          return "服务请求";
        case "408":
          return "设备预警";
        default:
          return "";
      }
    },
    filterService(serviceOrderItemList) {
      let result = [];
      if (serviceOrderItemList && serviceOrderItemList.length > 0) {
        this.serviceList.map((item) => {
          serviceOrderItemList.map((subItem) => {
            if (item.itemId == subItem.itemId) {
              result.push(item);
            }
          });
        });
      }
      return result;
    },
    // 获取服务对象列表
    async getServiceObjList(obj) {
      this.serviceApplyLoading = true;
      this.serviceApplyColumns = [
        {
          title: "姓名",
          width: 200,
          key: "userName",
        },
        {
          title: "地址",
          minWidth: 200,
          key: "addr",
        },
      ]
      let res = await this.$get(
        "/gateway/api/homecare/pc/ServicePeople/selectServicePeoplePage",
        {
          orgId: this.$core.getOrgId(),
          page: obj.page,
          pageSize: obj.pageSize,
        }
      );
      this.serviceApplyLoading = false;
      if (res.code == 200 && res.dataList) {
        this.serviceApplyList = res.dataList;
        this.serviceApplyPage = res.currentPage;
        this.serviceApplyPageSize = res.pageSize;
        this.serviceApplyTotal = res.maxCount
      }
    },
    
    // 获取服务内容/服务清单列表
    async getServiceList() {
      let res = await this.$get(
        "/gateway/api/homecare/pc/OrderOrgItem/selectOrderByOrgCode",
        {
          // orgCode: parent.vue.loginInfo.userinfo.orgCode,
          orgCode: 1,
        }
      );
      if (res.code == 200 && res.dataList) {
        this.serviceList = res.dataList;
      }
      return;
    },
    // 获取服务请求列表
    async getServiceApply(obj) {
      this.serviceApplyLoading = true;
       this.serviceApplyColumns = [
        {
          title: "姓名",
          width: 130,
          key: "userName",
        },
        {
          title: '服务名称',
          minWidth: 200,
          key: 'name',
        },
        {
          title: "服务内容",
          minWidth: 200,
          key: "content",
        },
        {
          title: "服务状态",
          width: 130,
          key: "content",
          render: (h,params) => {
            let result = '';
            if(params.row.status) {
              for(let k in this.serviceStatusList) {
                if(this.serviceStatusList[k].dictKey == params.row.status) {
                result = this.serviceStatusList[k].dictValue;
              }
            }
            }
            return h('div',result)
          }
        },
        {
          title: "创建时间",
          width: 180,
          key: "gmtCreate",
          render: (h,params) => {
            let result = '';
            if(params.row.gmtCreate) {
              result = this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd hh:mm:ss');
            }
            return h('div',result);
          }
        }
      ]
      let res = await this.$get('/gateway/api/homecare/pc/Order/selectServiceApplyByOrgCodeAndOrder',{
        orgCode: '1',
        // orgCode: parent.vue.loginInfo.userinfo.orgCode,
        page: obj.page,
        pageSize: obj.pageSize
      });
      this.serviceApplyLoading = false;
      if(res.code == 200 && res.dataList) {
        this.serviceApplyList = res.dataList;
        this.serviceApplyPage = res.currentPage;
        this.serviceApplyPageSize = res.pageSize;
        this.serviceApplyTotal = res.maxCount;
      }
    },

    // 创建工单
    async openCreateStatusFn(status, open) {
      if (this.serviceObjList.length == 0 && open) {
        await this.getServiceObjList({
          page: 1,
          pageSize: 20
        });
        this.serviceList.map((item) => {
          this.selectServiceList.push({
            id: item.itemId,
            itemId: "",
            num: 0,
          });
        });
      }
      this.openCreateStatus = status;
      if (status) {
        this.createFormData = { serviceOrder: "1",orderType: '15',userId: '' };
        this.errorServices = "";
      }
    },
    serviceApplyPageSizeFn(obj) {
      if(this.serviceApplyTypeStatus == '1') {
        this.getOrderWarningList({
           page: obj.page,
          pageSize: obj.pageSize
        })
      }else if(this.serviceApplyTypeStatus == '2'){
        this.getServiceApply({
          page: obj.page,
          pageSize: obj.pageSize
        });
      }else {
        this.getServiceObjList({
           page: obj.page,
          pageSize: obj.pageSize
        })
      }
    },
    changeOrderType(val) {
      this.createFormData.userId = '';
      this.serviceApplyUserObj = {};
      this.serviceApplyList = [];
      if((val == '14' || val == '15')) {
        this.serviceApplyTypeStatus = '3';
        this.selectObjTitle = "选择服务对象(点击列表项选中服务对象)"
        this.getServiceObjList({
          page: 1,
          pageSize: 20
        });
      }else if(val == '407'){
        this.serviceApplyTypeStatus = '2';
        this.selectObjTitle = "选择服务请求(点击列表项选中服务对象)"
        this.getServiceApply({
          page: 1,
          pageSize: 20
        })
      }else if(val == '408') {
        this.serviceApplyTypeStatus = '1';
        this.selectObjTitle = "选择预警设备(点击列表项选中服务对象)"
        this.getOrderWarningList({
           page: 1,
          pageSize: 20
        })
      }
    },
    // 获取预警设备列表
    async getOrderWarningList(obj) {
      this.serviceApplyLoading = true;
       this.serviceApplyColumns = [
         {
           title: '服务对象',
           width: 200,
          key: 'userName',
         },
        {
          title: '设备名称',
          width: 200,
          key: 'deviceName',
        },
        {
          title: "告警时间",
          minWidth: 200,
          key: "alarmTime",
        },
        {
          title: "告警描述",
          minWidth: 200,
          key: "alarmDesc",
        },
      ]
      let res = await this.$get('/gateway/api/homecare/app/DeviceAlarm/selectAlarmByOrgCode',{
        orgCode: '1',
        // orgCode: parent.vue.loginInfo.userinfo.orgCode,
        page: obj.page,
        pageSize: obj.pageSize
      });
      this.serviceApplyLoading = false;
      if(res.code == 200 && res.dataList) {
        this.serviceApplyList = res.dataList;
        this.serviceApplyPage = res.currentPage;
        this.serviceApplyPageSize = res.pageSize;
        this.serviceApplyTotal = res.maxCount;
      }
    },
    //设备预警/服务请求表格选择单行
    currentChange(newRow) {  
      this.serviceApplyUserObj = newRow;
    },
    serviceApplyStatusFn(status) {
      if(status) {
        this.serviceApplyHeight = window.innerHeight - 316 + 'px';
      }
      this.serviceApplyStatus = status;
    },
    saveServiceApply() {
      this.createFormData.userId = this.serviceApplyUserObj.userId;
      this.serviceApplyStatus = false;
      console.log(this.createFormData);
      console.log(this.serviceApplyUserObj);
    },
    async saveCreateFn() {
      if(this.currentCreateStatus) return;
      // let validateStatus = await this.$refs.createForm.validate();
      // if (this.selectServiceOption.length == 0) {
      //   this.errorServices = "请选择服务内容";
      //   return;
      // }
      // if (!validateStatus) return;
      this.currentCreateStatus = true;
      let params = {
        ...this.createFormData,
        services: (() => {
          let result = [];
          this.selectServiceList.map((item) => {
            if (item.itemId) {
              result.push({
                itemId: item.itemId,
                num: item.num,
              });
            }
          });
          return result;
        })(),
        bindId: this.serviceApplyTypeStatus == '1' ? this.serviceApplyUserObj.alarmId : this.serviceApplyTypeStatus == '2' ? this.serviceApplyUserObj.applyId : '',
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        familyId: this.serviceApplyTypeStatus == '1' ? this.serviceApplyUserObj.familyId : '',
        orderId: "",  //工单id/修改才传
        orgCode: "1",
        // orgCode: parent.vue.loginInfo.userinfo.orgCode,
        serviceStatus: "1",
        status: this.createFormData.serviceOrder == "1" ? "1" : "3",
      };
      let res = await this.$post(
        "/gateway/api/homecare/pc/Order/createOrder",
        params,
        { "Content-Type": "application/json" }
      );
      if (res.code == 200 && res.desc == "success") {
        this.$Message.success({
          background: true,
          content: "提交成功",
        });
        this.openCreateStatusFn(false);
        this.hadlePageSize({
          page: this.page,
          pageSize: this.pageSize,
        });
      } else {
        this.$Message.error({
          background: true,
          content:
            res.desc && res.desc.length < 50
              ? res.desc
              : "提交失败，请联系管理员处理",
        });
      }
      this.currentCreateStatus = false;
    },
    createTableSelect(arr) {
      this.selectServiceOption = arr;
      let arrId = [];
      arr.map((item) => {
        arrId.push(item.itemId);
      });

      this.selectServiceList.map((item) => {
        if (arrId.indexOf(item.id) != -1) {
          item.itemId = arrId[arrId.indexOf(item.id)];
        } else {
          item.itemId = "";
        }
      });
      if (arr.length > 0) this.errorServices = "";
    },

    // 派单
    async openToDispatch(params) {
      this.$Message.loading({
        content: "正在加载数据...",
        duration: 0,
      });
      let res = await this.$post(
        "/gateway/api/homecare/pc/Order/selectOrderByOrgCodeAndOrderId",
        params
      );
      this.$Message.destroy();
      if (res.code == 200 && res.data) {
        this.dispatchStatus = true;
        this.dispatchForm = res.data;
      } else {
        this.$Message.error({
          background: true,
          content:
            res.desc && res.desc.length < 20
              ? res.desc
              : "获取数据失败，请联系管理员处理",
        });
      }
    },
    dispatchFn(status) {
      if (status && this.staffList.length == 0) {
        this.getStaffList();
      }
      if(status) {
          this.dispatchFormData = {};
        }
        this.dispatchStatus = status;
     
    },
    async saveDispatchFn() {
      let validateStatus = await this.$refs.dispatchForm.validate();
      if(!validateStatus) return;
      this.currentDispatchStatus = true;
      let res = await this.$post('/gateway/api/homecare/pc/Order/distributeOrder',{
        orderId: this.dispatchForm.orderId,
        orgCode: this.dispatchForm.orgCode,
        ...this.dispatchFormData,
        status: this.currentDisaptchOrCancel ? '2' : '5'
      });
      this.currentDispatchStatus = false;
      if(res.code == 200 && res.desc == 'success') {
        this.$Message.success({
          background: true,
          content: "提交成功"
        });
        this.dispatchStatus = false;
        this.hadlePageSize({
          page: this.page,
          pageSize: this.pageSize
        })
      }else {
        this.$Message.error({
          background: true,
          content: res.desc && res.desc.length < 20
              ? res.desc
              : "提交失败，请联系管理员处理",
        });
      }
    },
    async getStaffList() {
      let res = await this.$get('/gateway/api/auth/pc/auth/selectOrgRoleUserByRoleCode',{
        roleCode: 'BUSINESS',
        orgCode: '1',
        // orgCode: parent.vue.loginInfo.userinfo.orgCode
      });
      if(res.code == 200 && res.dataList) {
        this.staffList = res.dataList;
      }else {
        this.$Message.error({
          background: true,
          content: res.desc
        })
      }
    },

    // 查看工单
    openSeeStatusFn(status) {
      this.openSeeStatus = status;
    },
    async seeToRow(params) {
      this.$Message.loading({
        content: "正在加载数据...",
        duration: 0,
      });
      this.currentStep = 1;
      let res = await this.$post(
        "/gateway/api/homecare/pc/Order/selectOrderByOrgCodeAndOrderId",
        params
      );

      this.$Message.destroy();
      if (res.code == 200 && res.data) {
        this.openSeeStatus = true;
        // if (res.data.serviceTypeId) {
        //   this.serviceTypeList.map((item) => {
        //     if (item.serviceTypeId == res.data.serviceTypeId) {
        //       res.data.serviceType = item.name;
        //     }
        //   });
        // }
        this.seeRowData = res.data;
        if (res.data.serviceStatus) {
          switch (res.data.serviceStatus) {
            // 1：未接单；2：已接单；3：已完成；4：未完成；5：已取消
            case "1":
              this.currentStep = 2;
              this.seeStepsList = [
                { title: "创建工单", content: "", status: "finish" },
                { title: "派单", content: "", status: "finish" },
                { title: "接单", content: "", status: "wait" },
                { title: "已完成", content: "", status: "wait" },
              ];
              break;
            case "2":
              this.currentStep = 3;
              this.seeStepsList = [
                { title: "创建工单", content: "", status: "finish" },
                { title: "派单", content: "", status: "finish" },
                { title: "接单", content: "", status: "finish" },
                { title: "已完成", content: "", status: "process" },
              ];
              break;
            case "3":
              this.currentStep = 4;
              this.seeStepsList = [
                { title: "创建工单", content: "", status: "finish" },
                { title: "派单", content: "", status: "finish" },
                { title: "接单", content: "", status: "finish" },
                { title: "已完成", content: "", status: "finish" },
              ];
              break;
            case "4":
              this.currentStep = 4;
              this.seeStepsList = [
                { title: "创建工单", content: "", status: "finish" },
                { title: "派单", content: "", status: "finish" },
                { title: "接单", content: "", status: "finish" },
                { title: "未完成", content: "", status: "error" },
              ];
              break;
            case "5":
              this.currentStep = 4;
              this.seeStepsList = [
                { title: "创建工单", content: "", status: "finish" },
                { title: "派单", content: "", status: "finish" },
                { title: "接单", content: "", status: "finish" },
                { title: "已取消", content: "", status: "error" },
              ];
              break;
          }
        }
      } else {
        this.$Message.error({
          background: true,
          content:
            res.desc && res.desc.length < 20
              ? res.desc
              : "获取数据失败，请联系管理员处理",
        });
      }
    },
    searchFn() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/gateway/api/homecare/pc/Order/selectOrderByOrgCode", {
        keyword: this.searchData.keyword,
        // orgCode: parent.vue.loginInfo.userinfo.orgCode,
        orgCode: 1,
        page: obj.page,
        pageSize: obj.pageSize,
      })
        .then((res) => {
          if (res.code == 200) {
            if (res.dataList && res.dataList.length > 0) {
              res.dataList.map((item) => {
                item.gmtCreate = item.gmtCreate
                  ? this.$core.formatDate(
                      new Date(item.gmtCreate),
                      "yyyy-MM-dd hh:mm:ss"
                    )
                  : "";
              });
            }
            this.tableData = res.dataList || [];
            this.total = res.maxCount;
            this.pageSize = res.pageSize;
            this.page = res.currentPage;
            this.loading = false;
          } else {
            this.loading = false;
            this.$Message.error({
              background: true,
              content:
                res.desc && res.desc.length < 20
                  ? res.desc
                  : "获取数据失败，请联系管理员处理",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
  },
  async created() {
    await this.getServiceStatus('SERVICE_STATUS','serviceStatusList');
    await this.getServiceStatus('SERVICE_ORDER_STATUS','orderStatusList');
    await this.getServiceList();
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>

<style lang="less" scoped>
.see-modal {
  background: #eee;
  width: 100%;
  height: calc(100vh - 172px);
  overflow: hidden;
  .steps {
    background: #fff;
    padding: 7px 20px;
  }
  .content {
    padding: 10px;
    height: calc(100vh - 207px);
    overflow: auto;
  }
  .card {
    .ivu-card-body {
      & > div {
        line-height: 40px;
      }
      .list {
        .seeTable {
          margin-left: 1em;
        }
      }
    }
    .tf-title {
      color: #515a6e;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.services {
  /deep/.ivu-form-item-label {
    width: calc(100% - 10px) !important;
    text-align: left;
    margin-left: 10px;
  }
  /deep/.ivu-form-item-content {
    margin-left: 10px !important;
  }
  /deep/.validate {
    &::before {
      content: "*";
      display: inline-block;
      margin-right: 4px;
      line-height: 1;
      font-family: SimSun;
      font-size: 14px;
      color: #ed4014;
    }
  }
}
.dispatch {
  .dispatch-modal {
    height: calc(100vh - 123px);
    overflow: auto;
    padding: 10px;
    background: #eee;
    .card {
      .ivu-card-body {
        & > div {
          line-height: 40px;
        }
        .list {
          .seeTable {
            margin-left: 1em;
          }
        }
      }
      .tf-title {
        color: #515a6e;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
/deep/.ivu-table-row-highlight {
  td {
    background-color: #19be6b !important;
    color: #fff;
  }
}
/deep/.table-list {
  &>div {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
}
.select_obj {
  /deep/.ivu-table {
    overflow: auto;
  }
}
</style>